import React from 'react'
import { graphql, StaticQuery } from 'gatsby';
import style from './navigation.module.scss';
import Link from '../link';

import useLiveData from 'hooks/useLiveData';

//function chunk (items = [], size = 1) {
//	const chunks = []
//	items = [].concat(...items)

//	while (items.length) {
//		chunks.push(
//			items.splice(0, size)
//		)
//	}

//	return chunks
//}

export default (props) => (
	<StaticQuery
		query={graphql`
			{
				allContentMenu(filter: {menuID: {eq: "main"}}) {
					edges {
						node {
							pages
						}
					}
				}
				allServicesService(filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}, sort: {fields: title, order: ASC}) {
					edges {
						node {
							permalink
							title
						}
					}
				}
			}
		`}
		render={data => {
			const { allServicesService, allContentMenu } = data;
 			let classes = [style.container, style.dropdownMenu];
			let services = [];
			if (props.collapsed) classes.push(style.collapsed);
			if (allServicesService && allServicesService.edges.length) services = allServicesService.edges;

			if (services) services = services.map(({ node }) => node);

			const { edges: menuEdges } = allContentMenu;
			let mainMenu = menuEdges.map(({ node }) => node.pages)[0];
			const { statusText: providerStatusText, status: providerStatus, response: providerResponse } = useLiveData({
				type: 'ContentMenu',
				staticData: data,
			});
			if ((providerResponse.data.length > 0 || !!mainMenu !== false) && providerStatusText === "LOADED") {
				mainMenu = providerResponse.data.filter(datum => datum.menuID === "main")[0].pages;
			}

			return (
				<nav datalayer="Primary Nav" className={classes.join(' ')}>
					{
						mainMenu.map((item, index) => {
							let { visible, target, href, text, children } = item;
							if (!href) href = '#';
							const targetAttr = target;

							if (visible) {
								if (text === 'Services') {
									return (
										<li className={style.linkWrapper}>
											<Link className={`${style.link}`} to="/services/">Services</Link>
											<div className={`${style.menu} ${style.col}`}>
												{ services.map(({ title, permalink }, index) => <div className={[style.dropdownItem, style.aboutDropdown, "cell", style.colItem].join(' ')} key={index}><Link className={style.dropwdownLink} to={`/services/${permalink}/`}>{ title }</Link></div> )}
												<div className={[style.dropdownItem, style.aboutDropdown, "cell"].join(' ')}><Link className={style.dropwdownLink} href="/portfolio/">Portfolio</Link></div>
											</div>
										</li>
									)
								} else if (children && children.length) {
									return (
										<li className={style.linkWrapper} key={index}>
											<Link className={style.link} to={href} target={targetAttr}>{text}</Link>
											<div className={style.menu}>
												<div className="grid-x grid-padding-x small-up-2">
													{ children.map((child, index) => {
														const { visible, target, href, text } = child;
														const targetAttr = target;

														if (visible) {
															// console.log(href)
															if (href.indexOf("#") !== -1) {
																return (
																	<div className={[style.dropdownItem, style.aboutDropdown, "cell"].join(' ')} key={index}>
																		<a className={style.dropwdownLink} href={href}>{text}</a>
																	</div>
																)
															}
															return (
																<div className={[style.dropdownItem, style.aboutDropdown, "cell"].join(' ')} key={index}>
																	<Link className={style.dropwdownLink} to={href} target={targetAttr}>{text}</Link>
																</div>
															)
														}
													})}
												</div>
											</div>
										</li>
									)
								} else {
									return (
										<li key={index}>
											<Link className={style.link} to={href} target={targetAttr}>{text}</Link>
										</li>
									)
								}
							}
						})

					}
				</nav>
			)
		}}
	/>
)